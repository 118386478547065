<template>
  <div class="bbr-exercises--new-instructions">
    <v-row>
      <v-col lg="8" md="12">
        <div class="mb-4 d-flex justify-space-between align-center">
          <h2 class="mr-auto">Program Extra Information</h2>

          <!-- <v-btn
            v-if="!isCompleted"
            class="primary--text mr-5"
            text
            depressed
            :loading="loading"
            @click="saveForLater"
          >
            Save & Finish Later
          </v-btn> -->

          <!-- <v-btn
            v-if="!hasEmptyDescription"
            class="ml-3 bg-primary-gradient primary"
            :loading="loading"
            @click="saveChanges"
          >
            <v-icon class="mr-3">
              {{ icons.save }}
            </v-icon>
            Save Changes
          </v-btn> -->

          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            @click="saveAndNext"
            :loading="loading"
            text
            depressed
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col lg="8" md="12">
        <program-rich-description-list
          :items="this.selectedProgram.rich_description"
          :loaing="loading"
          :setSelectedProgramDescription="setSelectedProgramDescription"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import { mdiPlus, mdiArrowRight } from '@mdi/js'

import ProgramRichDescriptionList from '@/components/forms/programs/ProgramRichDescription/index'
import ProgramMixin from '../mixins/ProgramMixin'

export default {
  name: 'ProgramRichDescription',
  mixins: [ProgramMixin],
  components: {
    ProgramRichDescriptionList,
  },
  data() {
    return {
      loading: false,
      icons: {
        add: mdiPlus,
        next: mdiArrowRight,
      },
    }
  },

  computed: {
    ...mapGetters({
      isCompleted: 'programs/isSelectedProgramComplete',
    }),

    ...mapState({
      selectedProgram: (state) => state.programs.selectedProgram,
    }),
    isEditable() {
      return !this.isCompleted
    },
    hasRichDescriptions() {
      return !!(
        this.program.rich_description && this.program.rich_description.length
      )
    },

    hasEmptyDescription() {
      if (!this.hasRichDescriptions) return false

      return !!this.program.rich_description.filter((item) => {
        return !item && !item.title
      }).length
    },
  },
  methods: {
    ...mapMutations({
      setSelectedProgramDescription: 'programs/setSelectedProgramDescription',
    }),

    async saveAndNext() {
      this.loading = true
      await this.save().then(() => {
        if (this.isCompleted) {
          this.$router.push({
            name: 'program.checkins',
            params: { id: this.$attrs.id },
          })
        } else {
          this.$router.push({
            name: 'new.program.checkins',
            params: { id: this.$attrs.id },
          })
        }
      }, 600)
    },
  },
}
</script>

<style lang="scss" scoped>
.moving-instruction {
  opacity: 0.1 !important;
  color: transparent !important;
  border: 3px dashed var(--v-primary-base) !important;
  background-color: var(--v-grey-base) !important;

  .instruction-input {
    opacity: 0 !important;
    color: transparent !important;
    background-color: var(--v-grey-base) !important;
  }
}

.sortable-drag {
  opacity: 1 !important;
  cursor: pointer !important;
  border-radius: 4px !important;
  background-color: white !important;
  border: 1px solid var(--v-grey-base) !important;

  .v-divider {
    visibility: hidden !important;
  }
}
</style>
